<template>
    <div>
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'"  v-disable-all="!pageMeta.permissions.edit">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id"><FormClient :client_id="client_id" @getClient="getClient" /><br /></div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div
                    v-for="(line_section, line_section_ndx) in rec.line_sections"
                    :key="line_section_ndx"
                    class="checklistform_section"
                >
                    <div v-if="line_section.section_header != ''" class="section-header cb-header">
                        <h3>
                            {{ line_section.section_header }}
                        </h3>
                    </div>

                    <div class="flex cb-row cb-titles">
                        <div class="cb-description"><!-- empty div for flex --></div>
                        <div
                            class="flex align-center checkboxes cb-titles dark-text"
                            :class="line_section.num_cols_class"
                        >
                            <div
                                v-for="(v2, k2) in line_section.checkbox_options"
                                :key="k2"
                                class="checklistform_checkbox_header"
                            >
                                {{ v2.opt }}
                            </div>
                        </div>
                    </div>

                    <div v-for="(line_val, line_ndx) in line_section.lines" :key="line_ndx">
                        <div v-if="line_ndx > 0" class="flex cb-row avoid-break">
                            <div v-if="line_val.type == 'header'" class="cb-header">
                                <h3>{{ line_val.txt }}</h3>
                            </div>
                            <template v-else-if="line_val.type == 'txt'">
                                <div class="cb-description">
                                    <label>{{
                                        (!line_section.hide_q_numbers ? line_val.line_num + '. ' : '') + line_val.txt
                                    }}</label>
                                </div>
                                <div class="checkboxes flex" :class="line_section.num_cols_class">
                                    <div
                                        v-for="(v4, k4) in line_section.checkbox_options"
                                        :key="k4"
                                        class="align-center checklistform_line_checkbox"
                                    >
                                        <input class="color"
                                            v-if="k4"
                                            type="radio"
                                            :value="k4"
                                            :name="'choice_' + line_section_ndx + '_' + line_ndx"
                                            v-model="rec.line2checkbox_data[line_section_ndx][line_ndx]"
                                            @change="cb_sums"
                                        />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <!--                    <div class="flex cb-row">-->
                    <!--                        <div class="cb-description">&lt;!&ndash; empty div for flex &ndash;&gt;</div>-->
                    <!--                        <div class="flex align-center checkboxes cb-titles" :class="line_section.num_cols_class" >-->
                    <!--                            <div v-for="(v2, k2) in line_section.checkbox_options" :key="k2" class="checklistform_checkbox_header"> {{ v2.opt }} </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div v-if="line_section.show_counts" class="flex cb-row">
                        <div class="cb-description"><label>Counts: &nbsp;</label></div>
                        <div class="checkboxes flex sumboxes" :class="line_section.num_cols_class">
                            <div
                                v-for="(v4, checkbox_num) in line_section.checkbox_options"
                                :key="checkbox_num"
                                class="align-center checklistform_line_checkbox"
                            >
                                <input
                                    v-if="checkbox_num > 0"
                                    class="sumbox"
                                    type="text"
                                    v-model="rec.checkbox_count[line_section_ndx][checkbox_num]"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="line_section.show_weights" class="flex cb-row">
                        <div class="cb-description"><label>Weights: &nbsp;</label></div>
                        <div class="checkboxes flex sumboxes" :class="line_section.num_cols_class">
                            <div
                                v-for="(v4, checkbox_num) in line_section.checkbox_options"
                                :key="checkbox_num"
                                class="align-center checklistform_line_checkbox"
                            >
                                <input v-if="checkbox_num > 0" class="sumbox" type="text" v-model="v4.weight" />
                            </div>
                        </div>
                    </div>

                    <div v-if="line_section.show_sums" class="flex cb-row">
                        <div class="cb-description"><label>Weighted Sums: &nbsp;</label></div>
                        <div class="checkboxes flex sumboxes" :class="line_section.num_cols_class">
                            <div
                                v-for="(v4, checkbox_num) in line_section.checkbox_options"
                                :key="checkbox_num"
                                class="align-center checklistform_line_checkbox"
                            >
                                <input
                                    class="sumbox"
                                    type="text"
                                    v-if="checkbox_num"
                                    v-model="rec.checkbox_weighted[line_section_ndx][checkbox_num]"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="line_section.show_total" class="flex cb-row total">
                        <div class="cb-description">
                            <label>{{ line_section.total_name || 'TOTAL WEIGHTED' }}: &nbsp;</label>
                        </div>
                        <div class="sumboxes" :class="line_section.num_cols_class">
                            <div class="total-weighted">{{ rec.total_weighted[line_section_ndx] }}</div>
                        </div>
                    </div>

                    <div v-if="line_section.section_footer != ''" class="section-footer cb-footer">
                        <div>
                            {{ line_section.section_footer }}
                        </div>
                    </div>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right inline-block secondary right-15"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                </div>
                <FormSignature
                    :user_id="user_id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    export default {
        name: 'ChecklistForm',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                await this.init_extra();
                this.loading = 0;
            },
            async cb_sums() {
                this.rec.checkbox_count = [];
                this.rec.total_weighted = [];
                this.rec.checkbox_weighted = [];
                for (let line_section in this.rec.line_sections) {
                    this.rec.checkbox_count[line_section] = [];
                    this.rec.checkbox_weighted[line_section] = [];
                    let cb_count = 0,
                        total_weighted = 0;
                    for (let checkbox_num in this.rec.line_sections[line_section].checkbox_options) {
                        if (checkbox_num > 0) {
                            cb_count = 0;
                            for (let line in this.rec.line_sections[line_section].lines) {
                                if (line > 0 && this.rec.line2checkbox_data[line_section][line] == checkbox_num) {
                                    cb_count++;
                                }
                            }
                            this.rec.checkbox_count[line_section][checkbox_num] = cb_count;
                            this.rec.checkbox_weighted[line_section][checkbox_num] =
                                cb_count * this.rec.line_sections[line_section].checkbox_options[checkbox_num].weight;
                            total_weighted += this.rec.checkbox_weighted[line_section][checkbox_num];
                        }
                    }
                    this.rec.total_weighted[line_section] = total_weighted;
                    //this is quick way around the current issue (it actually works fine) - message to Alex from JC
                    //a more permanent fix is to utilize this.$set on all the properties with reactivity issues.
                    this.$forceUpdate();
                }
            },
            async init_extra() {
                let line_num = 0,
                    numcols = 0,
                    num_cols_class = '';
                for (let line_section in this.rec.line_sections) {
                    numcols = this.rec.line_sections[line_section].checkbox_options.length;
                    num_cols_class = 'columns-2-3';
                    if (numcols >= 4) {
                        num_cols_class = 'columns-4-6';
                    }
                    if (numcols >= 7) {
                        num_cols_class = 'columns-7-9';
                    }
                    this.rec.line_sections[line_section].num_cols_class = num_cols_class;

                    if (!this.rec.line_sections[line_section].show_counts) {
                        this.rec.line_sections[line_section].show_counts = 0;
                    }
                    if (!this.rec.line_sections[line_section].show_weights) {
                        this.rec.line_sections[line_section].show_weights = 0;
                    }
                    if (!this.rec.line_sections[line_section].show_sums) {
                        this.rec.line_sections[line_section].show_sums = 0;
                    }
                    if (!this.rec.line_sections[line_section].show_total) {
                        this.rec.line_sections[line_section].show_total = 0;
                    }
                    if (!this.rec.line2checkbox_data[line_section]) {
                        this.rec.line2checkbox_data[line_section] = [];
                    }
                    for (let line in this.rec.line_sections[line_section].lines) {
                        if (this.rec.line_sections[line_section].lines[line].type == 'txt') {
                            line_num++;
                        } else {
                            line_num = 0;
                        }
                        this.rec.line_sections[line_section].lines[line].line_num = line_num;
                        if (!this.rec.line2checkbox_data[line_section][line]) {
                            this.rec.line2checkbox_data[line_section][line] = 0;
                        }
                    }
                }
                await this.cb_sums();
                this.original_rec = this.$clone_obj(this.rec);
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
